import React, { useEffect, useState } from "react";
import { ClientOnly } from "../../react/client-only";
import { RequestFormModal } from "../modalform";
import Header, { AssistantHeader, AssistantHeaderMobile } from "../Header";
import Footer from "../Footer";
import { useMedia } from "use-media";
import Switch from "react-switch";
import { useCookieBanner } from "../hooks/useCookieBanner";
import {
  CookieConsentProvider,
  useCookieConsentContext,
} from "@use-cookie-consent/react";
import Cookies from "js-cookie";
//import "ti-icons/css/themify-icons.css";
//import "bootstrap/dist/css/bootstrap.min.css";

const close = require("../../assets/images/testimonials/close-cook.svg");

export default function Layout(props) {
  const [plan, setPlan] = useState("");
  const [showThanksPage, setShowThanksPage] = useState(false);
  const isMobile = useMedia({ maxWidth: "520px" });
  const isTab = useMedia("(min-width: 521px) and (max-width: 1024px)");

  return (
    <ClientOnly>
      <CookieConsentProvider useCookieConsentHooksOptions={{
        defaultConsent: {
          thirdParty: true
        }
      }}>
        <div className="wrapper_page">
          {plan == "" ? null : (
            <RequestFormModal
              setPlan={setPlan}
              plan={plan}
              showThanksPage={showThanksPage}
              setShowThanksPage={setShowThanksPage}
              fromPage={props.fromPage}
            />
          )}
          {props.logoFor === "ASSISTANT" && !isTab && !isMobile ? (
            <AssistantHeader
              backgroundColor={props.backgroundColor}
              logoFor={props.logoFor}
              logoAction={props.logoAction}
              hideMobileNavButton={props.hideMobileNavButton}
              betaClick={() => setPlan("homepage")}
            />
          ) : props.logoFor === "ASSISTANT" && isMobile ? (
            <AssistantHeaderMobile
              backgroundColor={props.backgroundColor}
              logoFor={props.logoFor}
              logoAction={props.logoAction}
              hideMobileNavButton={props.hideMobileNavButton}
              betaClick={() => setPlan("homepage")}
            />
          ) : (
            <Header
              backgroundColor={props.backgroundColor}
              logoFor={props.logoFor}
              logoAction={props.logoAction}
              hideMobileNavButton={props.hideMobileNavButton}
              betaClick={() => setPlan("homepage")}
            />
          )}

          {props.children}
          <CookiePopupImplementation />
          <Footer product={props.product} />
        </div>
      </CookieConsentProvider>
    </ClientOnly>
  );
}

export function CookiePopup() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isCookiePopupVisible, setCookiePopupVisible] = useState(true);

  const handlePreferenceClick = () => {
    setPopupVisible(true);
  };

  const handleCloseCookiePopup = () => {
    setCookiePopupVisible(false);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      {isCookiePopupVisible && (
        <section className="cookie-container">
          <div className="cookie-box">
            <div className="cookie-box-left">
              <div className="cookie-box-left-content">
                {isMobile ? (
                  <div className="cookie-box-right">
                    <a
                      onClick={handleCloseCookiePopup}
                      style={{ cursor: "pointer" }}
                    >
                      <img src={close} className="close-image-cookie"></img>
                    </a>
                  </div>
                ) : null}
                <p className="font-section-webinar-date mb-0 pb-0 line-height-18">
                  We use cookies to track visitors, measure ads, ad campaign
                  effectiveness and analyze site traffic. We may also share
                  information about your use of our site with 3rd parties. For
                  more info, see our Cookie Policy and our Privacy Policy. By
                  clicking on "Cookie Preference Manager" you can choose to
                  enable only certain cookie categories. By clicking "Accept
                  all" you agree to the storing of all cookies on your device.
                  In case you don't choose one of these options and use our
                  website we will treat it as if you have accepted all cookies.
                </p>
              </div>
              <div className="cookie-box-left-button">
                <button
                  className="cookie-button-preference-accept font-section-small-text-medium-case"
                  onClick={handlePreferenceClick}
                >
                  Preference
                </button>
                <button className="cookie-button-preference-accept font-section-small-text-medium-case">
                  Accept all
                </button>
              </div>
            </div>
            {!isMobile ? (
              <div className="cookie-box-right">
                <a
                  onClick={handleCloseCookiePopup}
                  style={{ cursor: "pointer" }}
                >
                  <img src={close} className="close-image-cookie"></img>
                </a>
              </div>
            ) : null}
          </div>
        </section>
      )}
      {isPopupVisible && (
        <>
          <div className="blur-background-preference" />{" "}
          <PreferencePopup onClose={handleClosePopup} />
        </>
      )}
    </>
  );
}

export function PreferencePopup({ onClose }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMedia({ maxWidth: "520px" });

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="preference-popup">
      <div className="preference-heading-box">
        <h2 className="font-section-sub-header-small">
          Privacy Preference Center
        </h2>
        <a onClick={onClose} style={{ cursor: "pointer" }}>
          <img src={close} className="close-image-cookie" alt="Close" />
        </a>
      </div>
      <div className="list-box-preference">
        {isMobile ? (
          preferenceData.map((data, index) => (
            <div key={index} className="button-box-preference">
              <button
                className={`font-section-small-text-medium-apps-heading button-preference cursor-pointer ${activeIndex === index ? "active-button-preference" : ""
                  }`}
                onClick={() => handleButtonClick(index)}
              >
                {data.buttonText}
              </button>
              {activeIndex === index && (
                <p className="font-section-small-signup-form">
                  {data.paragraph}
                </p>
              )}
            </div>
          ))
        ) : (
          <>
            <div className="button-box-preference">
              {preferenceData.map((data, index) => (
                <button
                  key={index}
                  className={`font-section-small-text-medium-apps-heading button-preference cursor-pointer ${activeIndex === index ? "active-button-preference" : ""
                    }`}
                  onClick={() => handleButtonClick(index)}
                >
                  {data.buttonText}
                </button>
              ))}
            </div>
            <div className="content-box-preference">
              <h3 className="font-section-small-text-medium-apps-heading">
                {preferenceData[activeIndex].heading}
              </h3>
              <p className="font-section-small-signup-form">
                {preferenceData[activeIndex].paragraph}
              </p>
            </div>
          </>
        )}
      </div>
      <button
        onClick={onClose}
        className="font-section-small-text-case-medium confirm-my-choice cursor-pointer"
      >
        Confirm My Choices
      </button>
    </div>
  );
}

export function LayoutOnServer(props) {
  const [plan, setPlan] = useState("");
  const [showThanksPage, setShowThanksPage] = useState(false);

  return (
    <div className="wrapper_page">
      {plan == "" ? null : (
        <RequestFormModal
          setPlan={setPlan}
          plan={plan}
          showThanksPage={showThanksPage}
          setShowThanksPage={setShowThanksPage}
          product
          fromPage={props.fromPage}
        />
      )}
      <Header
        backgroundColor={props.backgroundColor}
        logoFor={props.logoFor}
        betaClick={() => setPlan("homepage")}
      />
      {props.children}
      <Footer product={props.product} />
    </div>
  );
}

export function GdprPopup({ scrollPercentageToShowPopup }) {
  const isSmall = useMedia({ maxWidth: "520px" });
  const {
    acceptAllCookies,
    declineAllCookies,
    acceptCookies,
    consent,
  } = useCookieConsentContext();

  console.log("consent", consent);

  React.useEffect(() => {
    console.log("consent", consent);
    if (consent.thirdParty) {
      Cookies.set("gatsby-gdpr-google-tagmanager", true);
    } else {
      Cookies.set("gatsby-gdpr-google-tagmanager", false);
    }
  }, [consent]);

  // useAttachGdprListenersEffect(attachEvents);
  // useSetupGdprEffect();

  // const {
  //   bannerWasShown,
  //   manager,
  //   closeGdprBanner,
  //   resetAndShowBanner,
  //   enableManager,
  //   disableManager,
  //   toggleManager,
  //   enableGuard,
  //   disableGuard,
  //   toggleGuard,
  //   guardIsEnabled,
  //   enableForStorage,
  //   disableForStorage,
  //   toggleForStorage,
  // } = useGdpr();

  // const manager2 = useGdprManager();

  // console.log("GdprPopup :: DOING", manager , bannerWasShown , "3", manager2);

  // const [plan, setPlan] = useState("");
  // const [showThanksPage, setShowThanksPage] = useState(false);

  // const AUTOMATE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY =
  //   "AUTOMATE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY";

  // const POPUP_EXPIRY_IN_MINUTES = 5 * 60 * 1000;
  // // const POPUP_EXPIRY_IN_MINUTES = 1 * 10 * 10;

  // // console.log("AutoPopUp", AutoPopUp);

  // const percentToShowPopup = scrollPercentageToShowPopup
  //   ? scrollPercentageToShowPopup
  //   : 50;

  const [popupVisiblity, setPopupVisblity] = useState(true);

  // const [closedOnce, setCloesedOnce] = useState(false);

  // const [makeThankyouVisible, setMakeThankyouVisible] = useState("hide");

  // const scrollPercentage = useScrollPosition();

  // const uniqueId = useUniqueId();

  // function closeThankyouModal() {
  //   setMakeThankyouVisible("hide");
  //   window.history.pushState(
  //     {},
  //     "",
  //     window.location.origin + window.location.pathname
  //   );
  // }

  // const storedKey = getLocalStorageWithExpiry(
  //   AUTOMATE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY
  // );

  // const sessionPopupShownOnce =
  //   storedKey == null || storedKey == undefined || storedKey == ""
  //     ? "not-shown"
  //     : `shown::${storedKey.componentId}`;

  // const flogtoShowPopupBasedOnSession =
  //   sessionPopupShownOnce === `shown::${uniqueId}` ||
  //   sessionPopupShownOnce === "not-shown";

  // useDisableBodyScroll(
  //   popupVisiblity && flogtoShowPopupBasedOnSession && !closedOnce
  // );

  const closePopup = (event) => {
    setPopupVisblity(false);
    // closeGdprBanner()
    // setCloesedOnce(true);
  };

  // useEffect(() => {
  //   if (scrollPercentage > percentToShowPopup) {
  //     setPopupVisblity(true);
  //   }
  // }, [scrollPercentage]);

  // React.useEffect(() => {
  //   if (sessionPopupShownOnce === "not-shown" && popupVisiblity) {
  //     setLocalStorageWithExpiry(
  //       AUTOMATE_POPUP_SHOWN_IN_SESSION_STORAGE_PROPERTY,
  //       { route: window.location.href, componentId: uniqueId },
  //       POPUP_EXPIRY_IN_MINUTES
  //     );
  //   }
  // }, [popupVisiblity]);

  const toShowPopup = popupVisiblity;

  // console.log(
  //   "toShowPopup",
  //   toShowPopup,
  //   flogtoShowPopupBasedOnSession,
  //   popupVisiblity,
  //   !closedOnce
  // );

  //   return (<>
  //     {plan == "" ? null : (
  //       <RequestFormModal
  //         setPlan={setPlan}
  //         plan={plan}
  //         showThanksPage={showThanksPage}
  //         setShowThanksPage={setShowThanksPage}
  //       />)}
  //   </>)
  // }

  return toShowPopup ? (
    <>
      <section className="popup-background automate_popup new_automate_popup gdpr">
        <div class="container">
          <div className="row">
            <div class="col-md-11 col-sm-12 m-auto">
              <div className="close-circle cursor-pointer">
                <a class="close cursor-pointer" onClick={closePopup}>
                  &#9447;
                </a>
              </div>
              <div class="row popup popup-assistants popup_automate_update_23">
                <div class="content-left-popup col-sm-12 col-md-7">
                  <h3 className="font-section-sub-header-bold mb-0">
                    Automate your tasks, now!
                  </h3>
                  <h4 className="font-section-normal-text mb-3">
                    Have a repetitive process? Automate it in minutes.<br></br>{" "}
                    No coding required.
                  </h4>

                  <div className="line_or_pop">
                    <hr className="seperator-pop-demo hl-left"></hr>
                    <span className="font-section-normal-text">OR</span>
                    <hr className="seperator-pop-demo hl-right"></hr>
                  </div>
                  <div className="demo-container-pop">
                    <div>
                      <button onClick={acceptAllCookies}>Accept all</button>
                      <button
                        onClick={() => acceptCookies({ thirdParty: true })}
                      >
                        Accept third-party
                      </button>
                      <button
                        onClick={() => acceptCookies({ firstParty: true })}
                      >
                        Accept first-party
                      </button>
                      <button onClick={declineAllCookies}>Reject all</button>
                    </div>
                  </div>
                </div>
                <div className="content-right-popup col-md-5"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  ) : null;
}

export function CookiePopupImplementation() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isCookiePopupVisible, setCookiePopupVisible] = useState(true);
  const isMobile = useMedia({ maxWidth: "520px" });

  const {
    showCookieConfiguration,
    acceptAllCookies,
    consent,
    discardCookieSaving,
  } = useCookieBanner();

  console.log(
    "TODO ::: showCookieConfiguration",
    showCookieConfiguration,
    consent
  );
  const handlePreferenceClick = () => {
    setPopupVisible(true);
  };

  const handleCloseCookiePopup = () => {
    discardCookieSaving();
    setCookiePopupVisible(false);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      {showCookieConfiguration && (
        <section className="cookie-container">
          <div className="cookie-box">
            <div className="cookie-box-left">
              <div className="cookie-box-left-content">
                <p className="font-section-webinar-date mb-0 pb-0 line-height-18">
                  By using this website, you agree to the storing of{" "}
                  <a href="https://workativ.com/cookie-policy">cookies</a> on
                  your device to enhance site navigation, analyze site usage,
                  and assist in our marketing efforts. View our{" "}
                  <a href="https://workativ.com/privacy">Privacy Policy</a> for
                  more information.
                </p>
              </div>
              <div className="cookie-box-left-button">
                <button
                  className="cookie-button-preference-accept font-section-small-text-medium-case"
                  onClick={handlePreferenceClick}
                >
                  Manage Preference
                </button>
                <button
                  onClick={acceptAllCookies}
                  className="cookie-button-preference-accept font-section-small-text-medium-case"
                >
                  okay
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      {isPopupVisible && (
        <>
          <div className="blur-background-preference" />{" "}
          <PreferencePopupImplementation onClose={handleClosePopup} />
        </>
      )}
    </>
  );
}

export function PreferencePopupImplementation({ onClose }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMedia({ maxWidth: "520px" });

  const { consent, acceptCookies, acceptAllCookies } = useCookieBanner();
  const [marketing, setMarketing] = useState(
    consent.marketing ? consent.marketing : false
  );
  const [preferences, setPreferences] = useState(
    consent.preferences ? consent.preferences : false
  );
  const [thirdParty, setThirdParty] = useState(
    consent.thirdParty === false ? false : true
  );
  // const [showAllowAll , setShowAllowAll] = useState(false);

  const setActiveTab = (index) => {
    setActiveIndex(index);
  };

  const saveCookiePreference = () => {
    console.log("DOING :: saveCookiePreference called");

    const cookiePreferences = {
      marketing: marketing || false,
      thirdParty: thirdParty || false,
      preferences: preferences || false,
    };

    acceptCookies(cookiePreferences);
    onClose();
  };

  const showAllowAll =
    marketing !== consent.marketing ||
    preferences !== consent.preferences ||
    thirdParty !== consent.thirdParty;

  // const {
  //   acceptAllCookies,
  //   declineAllCookies,
  //   acceptCookies,
  //   consent,
  //   cookies
  // } = useCookieConsentContext();

  // console.log("DOING ::: consent", preferenceData[activeIndex]);

  return (
    <div className="preference-popup">
      <div className="preference-heading-box">
        <h2 className="font-section-sub-header-small">
          Privacy Preference Center
        </h2>
        <a onClick={onClose} style={{ cursor: "pointer" }}>
          <img src={close} className="close-image-cookie" alt="Close" />
        </a>
      </div>
      <div className="list-box-preference">
        <div className="content-box-preference">
          <>
            {preferenceData.map((item, index) => (
              <PreferenceItem
                key={index}
                heading={item.heading}
                paragraph={item.paragraph}
                type={item.type}
                isMarginPadding={item.isMarginPadding}
                config={
                  item.type === "necessary"
                    ? [true, () => { }]
                    : item.type === "marketing"
                      ? [marketing, setMarketing]
                      : item.type === "preferences"
                        ? [preferences, setPreferences]
                        : item.type === "thirdParty"
                          ? [thirdParty, setThirdParty]
                          : null
                }
              />
            ))}
          </>
        </div>
      </div>
      <div
        className={`d-flex  ${isMobile ? "flex-column" : "justify-content-end"
          }`}
      >
        <button
          onClick={() => {
            // acceptAllCookies()
            saveCookiePreference();
            onClose();
          }}
          className="font-section-small-text-case-medium confirm-my-choice cursor-pointer"
        >
          Confirm My Choices
        </button>
        {showAllowAll ? (
          <button
            onClick={() => {
              acceptAllCookies();
              onClose();
            }}
            className="font-section-small-text-case-medium confirm-my-choice cursor-pointer"
          >
            Allow All
          </button>
        ) : null}
      </div>
    </div>
  );
}

const PreferenceItem = ({
  heading,
  paragraph,
  type,
  isMarginPadding,
  config: [cookie, setCookie],
}) => {
  console.log("DOING :: PreferenceItem", type, cookie, setCookie, heading);
  const isMobile = useMedia({ maxWidth: "520px" });

  return (
    <>
      <>
        <div className="d-flex justify-content-between mb-2">
          <h3 className="font-section-small-text-medium-apps-heading">
            {heading}
          </h3>
          {type === "marketing" ||
            type === "thirdParty" ||
            type === "preferences" ? (
            <Switch onChange={setCookie} checked={cookie} />
          ) : type === "necessary" ? (
            <Switch checked={true} disabled /> // Use switch and disable it
          ) : null}
        </div>
        <p
          className={`font-section-small-signup-form border-bottom-one ${
            isMobile ? (isMarginPadding ? "mb-0" : "") : ""
          }`}
        >
          {paragraph}
        </p>
      </>
    </> 
  );
};

const preferenceData = [
  {
    heading: "Essential",
    paragraph:
      "These items are required to enable basic website functionality.",
    type: "necessary",
  },
  {
    heading: "Marketing",
    paragraph:
      "These items are used to deliver advertising that is more relevant to you and your interests. They may also be used to limit the number of times you see an advertisement and measure the effectiveness of advertising campaigns. Advertising networks usually place them with the website operator’s permission.",
    type: "marketing",
  },
  {
    heading: "Personalization",
    paragraph:
      "These items allow the website to remember choices you make (such as your user name, language, or the region you are in) and provide enhanced, more personal features. For example, a website may provide you with local weather reports or traffic news by storing data about your current location.",
    type: "preferences",
  },
  {
    heading: "Analytics",
    paragraph:
      "These items help the website operator understand how its website performs, how visitors interact with the site, and whether there may be technical issues. This storage type usually doesn’t collect information that identifies a visitor.",
    type: "thirdParty",
    isMarginPadding: true,
  },
];
